import React, {FunctionComponent, useEffect, useState} from 'react';
import {useApiContext, useConfigContext, useInputContext} from 'Context';
import {MultiSelect} from 'ui/_Shared';

export const HighlightTitel: FunctionComponent = () => {
    const {apiObjects} = useApiContext();
    const {input, setInput} = useInputContext();
    const {seite4} = useConfigContext();
    const [highlightTitel, setHighlightTitel] = useState<Array<string>>([]);

    useEffect(() => {
        !highlightTitel.length && apiObjects.beratungController
            .ermittleHighlightTitelUsingPOST({beratungInput: input})
            .then(value => !!value.length && setHighlightTitel(value));
    }, [setHighlightTitel, input, apiObjects.beratungController, highlightTitel]);

    return <>
        <div className='textblock'>
            <h2>{seite4.title}</h2>
            <p>{seite4.text}</p>
        </div>
        {!!highlightTitel.length && <MultiSelect label={seite4.frage1.label}
                                                 helperText={seite4.frage1.helperText}
                                                 value={input.highlightTitel || []}
                                                 onChangeHandler={val => setInput(input => ({
                                                     ...input,
                                                     highlightTitel: val as Array<string>
                                                 }))}
                                                 options={highlightTitel.map(titel => ({
                                                     label: titel,
                                                     value: titel
                                                 }))}/>}
    </>;
};