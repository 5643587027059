/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {ProduktKarte, ProduktKarteFromJSON, ProduktKarteToJSON} from './';

/**
 * 
 * @export
 * @interface ProduktGruppe
 */
export interface ProduktGruppe {
    /**
     * 
     * @type {Array<ProduktKarte>}
     * @memberof ProduktGruppe
     */
    produkte?: Array<ProduktKarte>;
    /**
     * 
     * @type {string}
     * @memberof ProduktGruppe
     */
    titel?: string;
}

export function ProduktGruppeFromJSON(json: any): ProduktGruppe {
    return ProduktGruppeFromJSONTyped(json, false);
}

export function ProduktGruppeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProduktGruppe {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'produkte': !exists(json, 'produkte') ? undefined : ((json['produkte'] as Array<any>).map(ProduktKarteFromJSON)),
        'titel': !exists(json, 'titel') ? undefined : json['titel'],
    };
}

export function ProduktGruppeToJSON(value?: ProduktGruppe | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'produkte': value.produkte === undefined ? undefined : ((value.produkte as Array<any>).map(ProduktKarteToJSON)),
        'titel': value.titel,
    };
}


