import React, {createContext, FunctionComponent, useContext, useEffect, useState} from 'react';
import {config as appConfig} from 'environment';
import {TextConfig} from 'models';

const INITIAL_STATE = {
    header: {
        title: 'BERATUNGSTOOL ONLINE',
        closeIcon: '/jportal/cms/juris/media/assets/icons/icon-close-cross-white.svg'
    },
    teaser: {
        titel: 'In wenigen Schritten zu Ihrer Lösung',
        text: 'Sie benötigen Unterstützung? Nutzen Sie unser kostenloses Online-Beratungstool.',
        buttonText: 'ZUM BERATUNGSTOOL',
        kontaktTitel: 'Sie möchten uns persönlich sprechen?',
        kontaktLink: '/jportal/nav/services/kontakt/index.jsp#cf',
        kontaktLinkText: 'Zum Kontaktformular'
    },
    kontakt: {
        header: {
            allgemein: {
                title: 'Beratungsservice für Interessenten',
                text: '<p>Unsere erfahrenen Produktexperten beraten Sie gerne auch persönlich bei der Auswahl der passenden Lösungen.<br/> Auf Wunsch kann ein unverbindlicher und kostenfreier Test des passenden Produktes im juris Portal vereinbart werden.</p>'
            },
            hochschule: {
                title: 'Beratungsservice für Interessenten aus Forschung und Lehre',
                text: '<p>Damit Sie juris Lösungen an Ihrer Hochschule bestmöglich einsetzen können, ist eine individuelle Beratung unerlässlich. Nutzen Sie einfach unser Kontaktformular oder kontaktieren Sie Ihren Ansprechpartner direkt.</p>'
            },
            student: {
                title: 'Beratungsservice für Studierende und Referendare',
                text: '<p>Das Beratungstool für Studierende und Referendare befindet sich derzeit noch im Aufbau. Gerne beraten wir Sie zu Lösungen im Studium und in den ersten Berufsjahren persönlich. Nutzen Sie einfach unser Kontaktformular oder kontaktieren Sie uns direkt.</p>'
            }
        },
        form: {
            title: 'Nutzen Sie unser Kontaktformular',
            subtitle: 'Ihre Kontaktdaten',
            felder: {
                nachricht: {
                    label: 'Bitte geben Sie hier Ihre Nachricht ein.'
                },
                name: {
                    label: 'Name*',
                    placeholder: 'Name',
                    fehlermeldung: 'Bitte geben Sie Ihren Namen ein'
                },
                organisation: {
                    label: 'Organisation*',
                    placeholder: 'Organisation',
                    fehlermeldung: 'Bitte geben Sie Ihre Organisation ein'
                },
                email: {
                    label: 'E-Mail*',
                    placeholder: 'E-Mail',
                    fehlermeldung: 'Bitte geben Sie eine gültige E-Mail-Adresse an'
                },
                telefon: {
                    label: 'Telefon',
                    placeholder: 'Telefon'
                },
                erreichbarkeit: {
                    label: 'Wann können wir Sie am besten erreichen?',
                    placeholder: 'Wann können wir Sie am besten erreichen?'
                },
                kundennummer: {
                    label: 'Kundennummer',
                    placeholder: 'Kundennummer'
                }
            },
            pflichtfeldHinweis: '* Pflichtfelder',
            datenschutzHinweis: 'Wie und wozu wir Ihre personenbezogenen Daten verarbeiten und welche Rechte Ihnen zustehen, können Sie bei Interesse unter <a href="/jportal/nav/services/datenschutz/index.jsp">juris.de/datenschutzhinweise</a> nachlesen. Gerne schicken wir Ihnen diese Informationen auf Wunsch auch zu. Fragen zum Thema Datenschutz richten Sie bitte unter <a href="mailto:datenschutzbeauftragter@juris.de">datenschutzbeauftragter@juris.de</a> an uns. Der Verarbeitung Ihrer personenbezogenen Daten können Sie jederzeit widersprechen, ohne dass hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen.',
            zurueckButtonLabel: 'ZURÜCK',
            sendenButtonLabel: 'Anfrage senden'
        },
        danke: {
            title: 'Wir haben Ihre Kontaktanfrage erhalten',
            text: 'Vielen Dank für Ihre Anfrage. Wir werden Sie so schnell wie möglich kontaktieren.',
            schliessenButtonText: 'Fenster schließen'
        }
    },
    persoenlicheBeratung: {
        text: '<strong>Persönliche Beratung</strong><br/><br/><p>Sie erreichen unsere erfahrenen Produktexperten persönlich unter <a href=\'tel:+4968158664466\'>0681 - 5866 4466</a></p>',
        linkText: 'zum Kontaktformular'
    },
    seite1: {
        title: 'Mit wenigen Fragen relevante Produkte finden',
        text: '<p>juris bietet eine Vielzahl digitaler Lösungen für jeden, der sich professionell mit juristischen Inhalten auseinandersetzt.</p><p>Beantworten Sie die folgenden Fragen zu Ihrem Bedarf. Sie erhalten umgehend eine Übersicht über diejenigen Produkte, mit denen Sie Ihren Arbeitsalltag effizient und rechtssicher gestalten können.</p>',
        frage1: {
            label: 'Für welches Einsatzgebiet suchen Sie eine Lösung?',
            options: [
                {
                    label: 'Anwaltskanzlei',
                    value: 'anwalt'
                },
                {
                    label: 'Notariat',
                    value: 'notar'
                },
                {
                    label: 'Steuerberatung & Wirtschaftsprüfung',
                    value: 'stbwp'
                },
                {
                    label: 'Studium & Referendariat',
                    value: 'student'
                },
                {
                    label: 'Forschung und Lehre',
                    value: 'hochschule'
                },
                {
                    label: 'Öffentliche Verwaltung',
                    value: 'verwaltung'
                },
                {
                    label: 'Unternehmen',
                    value: 'unternehmen'
                },
                {
                    label: 'Verband',
                    value: 'verband'
                }
            ]
        }
    },
    seite2: {
        title: 'Ihr Arbeitsumfeld',
        hinweisText: '<p>Mit diesen Fragen lässt sich besser einschätzen, wie in Ihrer Kanzlei gearbeitet wird und welche Produktzuschnitte am geeignetsten sind.</p>',
        frage1: {
            label: 'Wie viele Personen sollen mit juris arbeiten?',
            options: [
                {
                    label: '1-4 Personen',
                    value: '1-4'
                },
                {
                    label: '5-10 Personen',
                    value: '5-10'
                },
                {
                    label: 'mehr als 10 Personen',
                    value: '>10'
                }
            ]
        },
        frage2: {
            label: 'Fachanwaltschaft vorhanden?'
        },
        frage3: {
            label: 'DAV-Mitgliedschaft vorhanden?'
        },
        frage4: {
            label: 'Zusätzliche Erbringung anwaltlicher Leistungen?'
        },
        frage5: {
            label: 'Zusätzliche Erbringung notarieller Leistungen?'
        },
        frage6: {
            label: 'Aus welchen Bereichen?',
            helperText: 'mehrere Antworten möglich',
            options: [{label: 'Justiziare & Syndikusanwälte', value: 'just'},
                {label: 'Compliance', value: 'compliance'},
                {label: 'Datenschutz & IT', value: 'datenschutz'},
                {label: 'Betriebsrat', value: 'betriebsrat'},
                {label: 'Personalabteilung', value: 'personalabteilung'},
                {
                    label: 'Geschäftsleitung, Bilanzierung, Controlling & Steuern',
                    value: 'geschaeftsleitung'
                }]
        }
    },
    seite3: {
        title: 'Individuelle Bedarfsermittlung',
        hinweisText: '<p><strong>Nur tatsächliche Schwerpunkte angeben.</strong> Wählen Sie beim Kauf eines Produktes die <strong>kostenfreie Plus-Option</strong>. Hiermit beziehen Sie nur gelegentlich benötigte Inhalte zum günstigen Einzelpreis.</p>',
        frage1: {
            label: 'Welche Themenschwerpunkte sind neben einer soliden Grundausstattung besonders relevant?',
            helperText: 'mehrere Antworten möglich',
            alleLabel: 'Alle Themenschwerpunkte anzeigen (A-Z)',
            options: {
                alle: [
                    {
                        label: 'Arbeitsrecht',
                        value: 'Arbeitsrecht'
                    },
                    {
                        label: 'Außenwirtschaftsrecht',
                        value: 'Außenwirtschaftsrecht'
                    },
                    {
                        label: 'Bankrecht',
                        value: 'Bankrecht'
                    },
                    {
                        label: 'Compliance',
                        value: 'Compliance'
                    },
                    {
                        label: 'Datenschutzrecht',
                        value: 'Datenschutzrecht'
                    },
                    {
                        label: 'Erbrecht',
                        value: 'Erbrecht'
                    },
                    {
                        label: 'Familienrecht',
                        value: 'Familienrecht'
                    },
                    {
                        label: 'Handels- und Gesellschaftsrecht',
                        value: 'Handels- und Gesellschaftsrecht'
                    },
                    {
                        label: 'Insolvenzrecht',
                        value: 'Insolvenzrecht'
                    },
                    {
                        label: 'IT- und Medienrecht',
                        value: 'IT- und Medienrecht'
                    },
                    {
                        label: 'Medizinrecht',
                        value: 'Medizinrecht'
                    },
                    {
                        label: 'Miet- und WEG-Recht',
                        value: 'Miet- und WEG-Recht'
                    },
                    {
                        label: 'Öffentliches Recht',
                        value: 'Öffentliches Recht'
                    },
                    {
                        label: 'Öffentliches Dienstrecht',
                        value: 'Öffentliches Dienstrecht'
                    },
                    {
                        label: 'Sozialrecht',
                        value: 'Sozialrecht'
                    },
                    {
                        label: 'Steuerrecht',
                        value: 'Steuerrecht'
                    },
                    {
                        label: 'Steuerrecht (Otto Schmidt)',
                        value: 'Steuerrecht (Otto Schmidt)'
                    },
                    {
                        label: 'Steuerrecht (STOTAX)',
                        value: 'Steuerrecht (STOTAX)'
                    },
                    {
                        label: 'Strafrecht',
                        value: 'Strafrecht'
                    },
                    {
                        label: 'Straßenverkehrsrecht',
                        value: 'Straßenverkehrsrecht'
                    },
                    {
                        label: 'Versicherungsrecht',
                        value: 'Versicherungsrecht'
                    },
                    {
                        label: 'Wettbewerbsrecht',
                        value: 'Wettbewerbsrecht'
                    },
                    {
                        label: 'Zivil- und Zivilprozessrecht',
                        value: 'Zivil- und Zivilprozessrecht'
                    }
                ],
                anwalt: [
                    {
                        label: 'Arbeitsrecht',
                        value: 'Arbeitsrecht'
                    },
                    {
                        label: 'Datenschutzrecht',
                        value: 'Datenschutzrecht'
                    },
                    {
                        label: 'Familienrecht',
                        value: 'Familienrecht'
                    },
                    {
                        label: 'IT- und Medienrecht',
                        value: 'IT- und Medienrecht'
                    },
                    {
                        label: 'Miet- und WEG-Recht',
                        value: 'Miet- und WEG-Recht'
                    },
                    {
                        label: 'Steuerrecht',
                        value: 'Steuerrecht'
                    },
                    {
                        label: 'Straßenverkehrsrecht',
                        value: 'Straßenverkehrsrecht'
                    },
                    {
                        label: 'Zivil- und Zivilprozessrecht',
                        value: 'Zivil- und Zivilprozessrecht'
                    }
                ],
                notar: [
                    {
                        label: 'Erbrecht',
                        value: 'Erbrecht'
                    },
                    {
                        label: 'Miet- und WEG-Recht',
                        value: 'Miet- und WEG-Recht'
                    },
                    {
                        label: 'Zivil- und Zivilprozessrecht',
                        value: 'Zivil- und Zivilprozessrecht'
                    }
                ],
                stbwp: [
                    {
                        label: 'Bankrecht',
                        value: 'Bankrecht'
                    },
                    {
                        label: 'Steuerrecht',
                        value: 'Steuerrecht'
                    },
                    {
                        label: 'Steuerrecht (Otto Schmidt)',
                        value: 'Steuerrecht (Otto Schmidt)'
                    },
                    {
                        label: 'Steuerrecht (STOTAX)',
                        value: 'Steuerrecht (STOTAX)'
                    }
                ],
                verwaltung: [
                    {
                        label: 'Compliance',
                        value: 'Compliance'
                    },
                    {
                        label: 'Datenschutzrecht',
                        value: 'Datenschutzrecht'
                    },
                    {
                        label: 'Öffentliches Recht',
                        value: 'Öffentliches Recht'
                    },
                    {
                        label: 'Zivil- und Zivilprozessrecht',
                        value: 'Zivil- und Zivilprozessrecht'
                    }
                ],
                unternehmen: [
                    {
                        label: 'Arbeitsrecht',
                        value: 'Arbeitsrecht'
                    },
                    {
                        label: 'Außenwirtschaftsrecht',
                        value: 'Außenwirtschaftsrecht'
                    },
                    {
                        label: 'Compliance',
                        value: 'Compliance'
                    },
                    {
                        label: 'Datenschutzrecht',
                        value: 'Datenschutzrecht'
                    },
                    {
                        label: 'IT- und Medienrecht',
                        value: 'IT- und Medienrecht'
                    },
                    {
                        label: 'Sozialrecht',
                        value: 'Sozialrecht'
                    },
                    {
                        label: 'Steuerrecht',
                        value: 'Steuerrecht'
                    },
                    {
                        label: 'Wettbewerbsrecht',
                        value: 'Wettbewerbsrecht'
                    }
                ],
                verband: [
                    {
                        label: 'Arbeitsrecht',
                        value: 'Arbeitsrecht'
                    },
                    {
                        label: 'Außenwirtschaftsrecht',
                        value: 'Außenwirtschaftsrecht'
                    },
                    {
                        label: 'Bankrecht',
                        value: 'Bankrecht'
                    },
                    {
                        label: 'Compliance',
                        value: 'Compliance'
                    }
                ]
            }
        },
        frage2: {
            label: 'Ich möchte juris nutzen...',
            minLabel: 'als eine gelegentliche Unterstützung',
            maxLabel: 'als zentrales Tool im Arbeitsalltag',
            options: [1, 2, 3, 4, 5]
        }
    },
    seite4: {
        title: 'Vielen Dank!',
        text: 'Auf der nächsten Seite sehen Sie Ihre individuelle Produktempfehlungen.',
        frage1: {
            label: 'Auf welche Titel möchten Sie nicht verzichten?',
            helperText: 'Produkte mit diesen Titeln werden besonders berücksichtigt. Mehrere Antworten möglich.'
        }
    },
    stepper: {
        linkToBeratungstoolPage: '/jportal/nav/produkte/beratungstool/index.jsp',
        zurueckButtonText: 'Zurück',
        weiterButtonText: 'Weiter'
    },
    ergebnis: {
        jumbotron: {
            title: 'Unsere Produktempfehlungen',
            text: 'Unser Onlineberater hat auf Basis Ihrer Angaben die für Sie idealen juris Produkte herausgesucht.' +
                ' Schauen Sie sich einfach um und entdecken Sie die vielfältigen Lösungen, die juris für Sie im Angebot hat. Übrigens: Mit der Teilenfunktion erhalten Sie und Ihre Mitarbeiter später erneut Zugriff auf diese Seite.',
            bearbeitenLinkText: 'ANTWORTEN BEARBEITEN',
            teilenLinkText: 'Ergebnis teilen'
        },
        basis: {
            title: 'Basisprodukte für',
            text: '<strong>Produkte, die ein breites Spektrum an Gesetzen, Normen und Rechtsprechung abdecken.</strong><br/> Mit diesen Produkten erhalten Sie ein solides Fundament für Ihren Arbeitsalltag.'
        },
        vertiefung: {
            title: 'Vertiefung und Literatur rund um Ihre Themenschwerpunkte',
            text: '<strong>Produkte, mit denen Sie Antworten auf Ihre fachspezifischen Fragen finden.</strong><br/>Diese Produkte beinhalten Normen und Rechtsprechung zum gewählten Thema, ergänzt um eine umfangreiche Auswahl an Kommentaren, Literatur und bekannten Zeitschriften unserer Partnerverlage – immer aktuell für Sie aufbereitet und smart miteinander verknüpft.'
        },
        plusOption: {
            title: 'Plus-Option: Für alle, die nur gelegentlich in bestimmten Inhalten recherchieren',
            text: '<strong>Spartipp: Aktivieren Sie die Plus-Option beim Kauf eines juris Produktes kostenfrei.</strong><br/>Hiermit können Sie Inhalte anderer Produkte zum günstigen Einzelpreis beziehen ohne diese Produkte vollständig und dauerhaft erwerben zu müssen.',
            linkText: 'Mehr erfahren',
            linkTarget: '/jportal/nav/produkte/juris-plus-option/index.jsp'
        },
        smarteLoesungen: {
            title: 'Smarte Lösungen für mehr Effizienz und Produktivität',
            text: 'juris unterstützt Sie mit folgenden Produkten dabei, Ihre Abläufe effizienter zu gestalten und zu digitalisieren, um sich auf das Wesentliche konzentrieren zu können.'
        },
        kontaktStoerer: {
            imageSource: '/jportal/nav/bilder/content_bilder/jur-kontakt-jur.png',
            imageAlt: 'Kundenservice',
            textblock: '<h3>Sprechen Sie mit unseren Produktexperten!</h3><p><b>So erreichen Sie uns persönlich:</b></p><p>Mo-Do von 9 bis 18 Uhr <br/>Fr von 9 bis 17 Uhr</p><p>E-Mail: <a href=\'mailto:vertrieb@juris.de\'>vertrieb@juris.de</a><br/> Telefon: 0681 - 5866 4466</p>'
        }
    }
};

const ConfigContext = createContext<ConfigContextModel>(INITIAL_STATE);

interface ConfigContextModel extends TextConfig {
}

interface ConfigContextProviderProps {
}

export const ConfigContextProvider: FunctionComponent<ConfigContextProviderProps> = ({children}) => {
    const [config, setConfig] = useState<ConfigContextModel>(INITIAL_STATE);

    useEffect(() => {
        fetch(appConfig.i18nSource)
            .then<Response, TextConfig>(response => {
                if (!response.ok) {
                    throw Error('i18n file could not get retrieved');
                }
                return response.json();
            })
            .then(textConfig => setConfig(textConfig as TextConfig))
            .catch(e => {
                console.log('error fetching i18n file, falling back to defaults!\n', 'casued by:\n', e);
            });
    }, [setConfig]);

    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

export const useConfigContext = () => {
    const context = useContext(ConfigContext);
    if (context === undefined) {
        throw new Error('useConfigContext must be used within a ConfigContextProvider');
    }
    return context;
};