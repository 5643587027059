/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface BeratungInput
 */
export interface BeratungInput {
    /**
     * 
     * @type {boolean}
     * @memberof BeratungInput
     */
    anwaeltlicheLeistungen?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BeratungInput
     */
    anzahlPersonen?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BeratungInput
     */
    bereiche?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BeratungInput
     */
    branche?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BeratungInput
     */
    davMitgliedschaft?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BeratungInput
     */
    fachanwaltschaft?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BeratungInput
     */
    highlightTitel?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof BeratungInput
     */
    notarielleLeistungen?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BeratungInput
     */
    nutzung?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof BeratungInput
     */
    themengebiete?: Array<string>;
}

export function BeratungInputFromJSON(json: any): BeratungInput {
    return BeratungInputFromJSONTyped(json, false);
}

export function BeratungInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): BeratungInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anwaeltlicheLeistungen': !exists(json, 'anwaeltlicheLeistungen') ? undefined : json['anwaeltlicheLeistungen'],
        'anzahlPersonen': !exists(json, 'anzahlPersonen') ? undefined : json['anzahlPersonen'],
        'bereiche': !exists(json, 'bereiche') ? undefined : json['bereiche'],
        'branche': !exists(json, 'branche') ? undefined : json['branche'],
        'davMitgliedschaft': !exists(json, 'davMitgliedschaft') ? undefined : json['davMitgliedschaft'],
        'fachanwaltschaft': !exists(json, 'fachanwaltschaft') ? undefined : json['fachanwaltschaft'],
        'highlightTitel': !exists(json, 'highlightTitel') ? undefined : json['highlightTitel'],
        'notarielleLeistungen': !exists(json, 'notarielleLeistungen') ? undefined : json['notarielleLeistungen'],
        'nutzung': !exists(json, 'nutzung') ? undefined : json['nutzung'],
        'themengebiete': !exists(json, 'themengebiete') ? undefined : json['themengebiete'],
    };
}

export function BeratungInputToJSON(value?: BeratungInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anwaeltlicheLeistungen': value.anwaeltlicheLeistungen,
        'anzahlPersonen': value.anzahlPersonen,
        'bereiche': value.bereiche,
        'branche': value.branche,
        'davMitgliedschaft': value.davMitgliedschaft,
        'fachanwaltschaft': value.fachanwaltschaft,
        'highlightTitel': value.highlightTitel,
        'notarielleLeistungen': value.notarielleLeistungen,
        'nutzung': value.nutzung,
        'themengebiete': value.themengebiete,
    };
}


