import React, {FunctionComponent} from 'react';
import {useConfigContext, useInputContext} from 'Context';
import {Radio} from 'ui/_Shared';

export const Fachanwaltschaft: FunctionComponent = () => {
    const {input, setInput} = useInputContext();
    const {seite2} = useConfigContext();

    return <Radio label={seite2.frage2.label}
                  value={!!input.fachanwaltschaft ? 'Ja' : 'Nein'}
                  onChangeHandler={value => setInput(input => ({...input, fachanwaltschaft: value === 'Ja'}))}
                  options={[{label: 'Ja', value: 'Ja'}, {label: 'Nein', value: 'Nein'}]}/>;
};