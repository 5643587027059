import React, {FunctionComponent, ReactText} from 'react';

interface MultisSelectProps {
    label: string;
    helperText?: string;
    errorState?: boolean;
    value: Array<ReactText>;
    onChangeHandler: (value: Array<ReactText>) => void;
    options: Array<{ label: string, value: ReactText }>;
    buttonSize?: 'slim' | 'large';
}

export const MultiSelect: FunctionComponent<MultisSelectProps> = ({
                                                                      label,
                                                                      helperText,
                                                                      errorState,
                                                                      value,
                                                                      onChangeHandler,
                                                                      options,
                                                                      buttonSize = 'large'
                                                                  }) => {
    const onClickOptionHandler = (target: ReactText) => {
        if (value.includes(target)) {
            onChangeHandler([...value].filter(val => val !== target));
        } else {
            onChangeHandler([...value, target]);
        }
    };

    return !!options?.length
        ? <>
            <label className='advisory-tool__form-label'>{label}</label>
            <div className='advisory-tool__button-wrapper'>
                {!!helperText && <p>{helperText}</p>}
                <div className='row'>
                    {options.map((option, index) =>
                        <div className='col-sm-6 col-lg-3'
                             key={index}
                             onClick={() => onClickOptionHandler(option.value)}>
                            <div
                                className={['advisory-tool__button-rectangle', `advisory-tool__button-rectangle--${buttonSize}`,
                                    value.includes(option.value) ? 'advisory-tool__button-rectangle--active' : '',
                                    errorState ? 'advisory-tool__button-rectangle--invalid' : ''].join(' ')}>
                                <p><strong>{option.label}</strong></p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
        : null;
};