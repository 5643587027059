import React, {FunctionComponent, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {KontaktAnfrage} from '@juris/api-gen';
import {useApiContext, useConfigContext, useInputContext} from 'Context';
import {KontaktFormInput, KontaktFormTextarea} from './Controls';

export const KontaktForm: FunctionComponent = () => {
    const {push, goBack} = useHistory();
    const {apiObjects} = useApiContext();
    const {input} = useInputContext();
    const {kontakt} = useConfigContext();
    const [kontaktAnfrage, setKontaktAnfrage] = useState<KontaktAnfrage>({
        nachricht: '',
        name: '',
        organisation: '',
        email: '',
        telefon: '',
        erreichbarkeit: '',
        kundennummer: '',
        beratungInput: input
    });
    const [submitted, setSubmitted] = useState<boolean>(false);

    const validateEmail = (email: string) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

    const validate = () => {
        return !!kontaktAnfrage.nachricht
            && !!kontaktAnfrage.name
            && !!kontaktAnfrage.organisation
            && !!kontaktAnfrage.email
            && validateEmail(kontaktAnfrage.email);
    };

    const submitHandler = () => {
        setSubmitted(true);

        if (validate()) {
            apiObjects.kontaktController.sendeKontaktAnfrageUsingPOST({kontaktAnfrage})
                .then(() => push('/kontakt/danke'));
        }
    };

    return <>
        <div className='container'>
            <div className='advisory-tool__form'>
                <div className='advisory-tool__form-wrapper'>
                    <form
                        className={
                            [
                                'form',
                                submitted ? 'form--submitted' : '',
                                submitted && !validate() ? 'form--invalid' : ''
                            ].join(' ')
                        }
                        noValidate={true}>
                        <div className='form__inner'>
                            <div className='form__intro'>
                                <h2 className='form__headline'>{kontakt.form.title}</h2>
                            </div>
                            <div className='form__content'>
                                <div className='form__main-content'>
                                    <fieldset className='form__fieldset'>
                                        <div className='form__fieldset-content'>
                                            <KontaktFormTextarea id='nachricht'
                                                                 label={kontakt.form.felder.nachricht.label}
                                                                 errorState={submitted && !kontaktAnfrage.nachricht}
                                                                 value={kontaktAnfrage.nachricht || ''}
                                                                 onChangeHandler={val => setKontaktAnfrage(
                                                                     contactFormInput => ({
                                                                         ...contactFormInput,
                                                                         nachricht: val
                                                                     }))}/>
                                        </div>
                                    </fieldset>
                                    <fieldset className='form__fieldset'>
                                        <legend className='form__fieldset-legend'>{kontakt.form.subtitle}</legend>
                                        <div className='form__fieldset-content'>
                                            <fieldset className='form__group'>
                                                <KontaktFormInput id='name'
                                                                  label={kontakt.form.felder.name.label}
                                                                  placeholder={kontakt.form.felder.name.placeholder}
                                                                  value={kontaktAnfrage.name || ''}
                                                                  onChangeHandler={value => setKontaktAnfrage(
                                                                      contactFormInput => ({
                                                                          ...contactFormInput,
                                                                          name: value as string
                                                                      }))}
                                                                  errorState={submitted && !kontaktAnfrage.name}
                                                                  errorMessage={kontakt.form.felder.name.fehlermeldung}/>
                                                <KontaktFormInput id='organisation'
                                                                  label={kontakt.form.felder.organisation.label}
                                                                  placeholder={kontakt.form.felder.organisation.placeholder}
                                                                  value={kontaktAnfrage.organisation || ''}
                                                                  onChangeHandler={value => setKontaktAnfrage(
                                                                      contactFormInput => ({
                                                                          ...contactFormInput,
                                                                          organisation: value as string
                                                                      }))}
                                                                  errorState={submitted && !kontaktAnfrage.organisation}
                                                                  errorMessage={kontakt.form.felder.organisation.fehlermeldung}/>
                                                <KontaktFormInput id='email'
                                                                  label={kontakt.form.felder.email.label}
                                                                  placeholder={kontakt.form.felder.email.placeholder}
                                                                  value={kontaktAnfrage.email || ''}
                                                                  onChangeHandler={value => setKontaktAnfrage(
                                                                      contactFormInput => ({
                                                                          ...contactFormInput,
                                                                          email: value as string
                                                                      }))}
                                                                  type='email'
                                                                  errorState={submitted && (!kontaktAnfrage.email || !validateEmail(kontaktAnfrage.email))}
                                                                  errorMessage={kontakt.form.felder.email.fehlermeldung}/>
                                                <div className='form__row row'>
                                                    <KontaktFormInput id='telefon'
                                                                      label={kontakt.form.felder.telefon.label}
                                                                      placeholder={kontakt.form.felder.telefon.placeholder}
                                                                      value={kontaktAnfrage.telefon || ''}
                                                                      onChangeHandler={value => setKontaktAnfrage(
                                                                          contactFormInput => ({
                                                                              ...contactFormInput,
                                                                              telefon: value as string
                                                                          }))}
                                                                      withFormRow={false}
                                                                      additionalCssClass='col-lg-6'/>
                                                    <KontaktFormInput id='erreichbarkeit'
                                                                      label={kontakt.form.felder.erreichbarkeit.label}
                                                                      placeholder={kontakt.form.felder.erreichbarkeit.placeholder}
                                                                      value={kontaktAnfrage.erreichbarkeit || ''}
                                                                      onChangeHandler={value => setKontaktAnfrage(
                                                                          contactFormInput => ({
                                                                              ...contactFormInput,
                                                                              erreichbarkeit: value as string
                                                                          }))}
                                                                      withFormRow={false}
                                                                      additionalCssClass='col-lg-6'/>
                                                </div>
                                                <KontaktFormInput id='kundennummer'
                                                                  label={kontakt.form.felder.kundennummer.label}
                                                                  placeholder={kontakt.form.felder.kundennummer.placeholder}
                                                                  value={kontaktAnfrage.kundennummer || ''}
                                                                  onChangeHandler={value => setKontaktAnfrage(
                                                                      contactFormInput => ({
                                                                          ...contactFormInput,
                                                                          kundennummer: value as string
                                                                      }))}/>
                                            </fieldset>
                                        </div>
                                    </fieldset>
                                </div>
                                <div className='form__info'>
                                    <small className='form__mandatory-info'>{kontakt.form.pflichtfeldHinweis}</small>
                                </div>
                                <div className='form__submit-container form__submit-container--text-full'>
                                    <div className='form__submit-text-container'>
                                        <small className='form__submit-small-print'
                                               dangerouslySetInnerHTML={{__html: kontakt.form.datenschutzHinweis}}/>
                                    </div>
                                    <div className='form__submit-btn-container'>
                                        <button onClick={() => goBack()}
                                                className='advisory-tool__nav-buttons--prev mr-4'
                                                type='button'>{kontakt.form.zurueckButtonLabel}</button>
                                        <button onClick={submitHandler}
                                                className='cta-button cta-button--button-gray cta-button--uppercase form__submit-btn'
                                                type='button'>{kontakt.form.sendenButtonLabel}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>;
};