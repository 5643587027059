/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {BeratungInput, BeratungInputFromJSON, BeratungInputToJSON} from './';

/**
 * 
 * @export
 * @interface KontaktAnfrage
 */
export interface KontaktAnfrage {
    /**
     * 
     * @type {BeratungInput}
     * @memberof KontaktAnfrage
     */
    beratungInput?: BeratungInput;
    /**
     * 
     * @type {string}
     * @memberof KontaktAnfrage
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof KontaktAnfrage
     */
    erreichbarkeit?: string;
    /**
     * 
     * @type {string}
     * @memberof KontaktAnfrage
     */
    kundennummer?: string;
    /**
     * 
     * @type {string}
     * @memberof KontaktAnfrage
     */
    nachricht?: string;
    /**
     * 
     * @type {string}
     * @memberof KontaktAnfrage
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof KontaktAnfrage
     */
    organisation?: string;
    /**
     * 
     * @type {string}
     * @memberof KontaktAnfrage
     */
    telefon?: string;
}

export function KontaktAnfrageFromJSON(json: any): KontaktAnfrage {
    return KontaktAnfrageFromJSONTyped(json, false);
}

export function KontaktAnfrageFromJSONTyped(json: any, ignoreDiscriminator: boolean): KontaktAnfrage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'beratungInput': !exists(json, 'beratungInput') ? undefined : BeratungInputFromJSON(json['beratungInput']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'erreichbarkeit': !exists(json, 'erreichbarkeit') ? undefined : json['erreichbarkeit'],
        'kundennummer': !exists(json, 'kundennummer') ? undefined : json['kundennummer'],
        'nachricht': !exists(json, 'nachricht') ? undefined : json['nachricht'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'organisation': !exists(json, 'organisation') ? undefined : json['organisation'],
        'telefon': !exists(json, 'telefon') ? undefined : json['telefon'],
    };
}

export function KontaktAnfrageToJSON(value?: KontaktAnfrage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'beratungInput': BeratungInputToJSON(value.beratungInput),
        'email': value.email,
        'erreichbarkeit': value.erreichbarkeit,
        'kundennummer': value.kundennummer,
        'nachricht': value.nachricht,
        'name': value.name,
        'organisation': value.organisation,
        'telefon': value.telefon,
    };
}


