import React, {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import {useConfigContext} from 'Context';

interface TeaserProps {
}

export const Teaser: FunctionComponent<TeaserProps> = () => {
    const {teaser} = useConfigContext();

    return (
        <section
            className='cs-text-cta-text content-section content-section--background content-section--violet-transparent in-viewport'>
            <div className='container'>
                <div className='text-cta-text'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='textblock'>
                                <h2>{teaser.titel}</h2>
                            </div>
                            <div className='call-to-action-tsr call-to-action-tsr--violet'>
                                <div className='row'>
                                    <div className='col-12 col-md-7'>
                                        <p className='call-to-action-tsr__text'>{teaser.text}</p>
                                    </div>
                                    <div className='col-12 col-md-4 offset-md-1'>
                                        <div className='call-to-action-tsr__button'>
                                            <Link className='cta-button cta-button--button-transparent'
                                                  to={'/fragen/branche?etcc_cu=onsite&etcc_cmp_onsite=Beratungstool&etcc_med_onsite=Button'}>{teaser.buttonText}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='textblock'>
                                <h3>{teaser.kontaktTitel}</h3>
                                <a href={teaser.kontaktLink} className='text-icon-link text-icon-link--link'>
                                    <span className='text-icon-link__icon'/>
                                    <span className='text-icon-link__text'>{teaser.kontaktLinkText}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};