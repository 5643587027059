import React, {FunctionComponent} from 'react';
import {BasisGruppe} from '@juris/api-gen';
import {BasisProduktKarte} from './_Shared';
import {useConfigContext} from 'Context';

interface BasisprodukteProps {
    basis: BasisGruppe;
}

export const BasisProdukte: FunctionComponent<BasisprodukteProps> = ({basis}) => {
    const {ergebnis} = useConfigContext();

    return <>
        <section className='cs-textblock content-section'>
            <div className='container'>
                <div className='textblock textblock--text-large'>
                    <h2>{ergebnis.basis.title} {basis.branche}</h2>
                    <p dangerouslySetInnerHTML={{__html: ergebnis.basis.text}}/>
                </div>
            </div>
        </section>
        <section className='container'>
            <div className='row'>
                {basis.produkte?.map((produkt, index) => <BasisProduktKarte key={index} {...produkt}/>)}
            </div>
        </section>
    </>;
};