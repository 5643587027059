import {FunctionComponent} from 'react';
import {ConfigContextProvider} from './ConfigContext';
import {InputContextProvider} from './InputContext';
import {HashRouter} from 'react-router-dom';
import {ApiContextProvider} from './ApiContext';

export * from './ApiContext';
export * from './ConfigContext';
export * from './InputContext';
export * from './StepperContext';

export const ContextAggregate: FunctionComponent = ({children}) => (
    <HashRouter>
        <ApiContextProvider>
            <ConfigContextProvider>
                <InputContextProvider>
                    {children}
                </InputContextProvider>
            </ConfigContextProvider>
        </ApiContextProvider>
    </HashRouter>
);