/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface LoginResultstring
 */
export interface LoginResultstring {
    /**
     * 
     * @type {Array<string>}
     * @memberof LoginResultstring
     */
    authorities?: Array<LoginResultstringAuthoritiesEnum>;
    /**
     * 
     * @type {string}
     * @memberof LoginResultstring
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResultstring
     */
    name?: string;
}

export function LoginResultstringFromJSON(json: any): LoginResultstring {
    return LoginResultstringFromJSONTyped(json, false);
}

export function LoginResultstringFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResultstring {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorities': !exists(json, 'authorities') ? undefined : json['authorities'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function LoginResultstringToJSON(value?: LoginResultstring | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authorities': value.authorities,
        'message': value.message,
        'name': value.name,
    };
}

/**
* @export
* @enum {string}
*/
export enum LoginResultstringAuthoritiesEnum {
    ADMIN = 'ADMIN'
}


