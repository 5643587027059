import React, {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import {useConfigContext} from 'Context';

interface PersoenlicheBeratungProps {
    showContactFormLink?: boolean;
}

export const PersoenlicheBeratung: FunctionComponent<PersoenlicheBeratungProps> = ({showContactFormLink}) => {
    const {persoenlicheBeratung} = useConfigContext();

    return (
        <div className='textblock'>
            <div dangerouslySetInnerHTML={{__html: persoenlicheBeratung.text}}/>
            {!!showContactFormLink && (
                <p>
                    <Link to='/kontakt' className='text-icon-link text-icon-link--link'>
                        <span className='text-icon-link__icon'/>
                        <span className='text-icon-link__text'>{persoenlicheBeratung.linkText}</span>
                    </Link>
                </p>
            )}
        </div>
    );
};