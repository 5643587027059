import React, {FunctionComponent} from 'react';
import {Header} from './Header';
import {Stepper} from './Stepper';

interface LayerProps {
    withStepper?: boolean;
}

export const Layer: FunctionComponent<LayerProps> = ({children, withStepper = false}) => {
    return (
        <div className='advisory-tool'>
            <div className='advisory-tool__backdrop'/>
            <div className='advisory-tool__layer'>
                <Header/>
                {children}
                {withStepper && <Stepper/>}
            </div>
        </div>
    );
};