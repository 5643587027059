import React, {
    createContext,
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useCallback,
    useContext,
    useState
} from 'react';
import {BeratungInput} from '@juris/api-gen';

const INITIAL_STATE = {
    branche: '',
    anzahlPersonen: '1-4',
    davMitgliedschaft: false,
    fachanwaltschaft: false,
    notarielleLeistungen: false,
    anwaeltlicheLeistungen: false,
    bereiche: [],
    themengebiete: [],
    nutzung: 3,
    highlightTitel: []
};

const InputContext = createContext<InputContextModel>({
    input: INITIAL_STATE,
    setInput: () => undefined,
    resetContext: () => undefined
});

interface InputContextModel {
    input: BeratungInput;
    setInput: Dispatch<SetStateAction<BeratungInput>>;
    resetContext: (branche: string) => void;
}

interface InputContextProviderProps {
}

export const InputContextProvider: FunctionComponent<InputContextProviderProps> = ({children}) => {
    const [input, setInput] = useState<BeratungInput>(INITIAL_STATE);

    const resetContext = useCallback((branche: string) => setInput({...INITIAL_STATE, branche: branche}), []);

    return <InputContext.Provider value={{input, setInput, resetContext}}>{children}</InputContext.Provider>;
};

export const useInputContext = () => {
    const context = useContext(InputContext);
    if (context === undefined) {
        throw new Error('useInputContext must be used within a InputContextProvider');
    }
    return context;
};