import React, {FunctionComponent} from 'react';
import {useHistory} from 'react-router-dom';
import {useConfigContext} from 'Context';
import {Body, PersoenlicheBeratung} from 'ui/_Shared';

export const Danke: FunctionComponent = () => {
    const {push} = useHistory();
    const {kontakt} = useConfigContext();
    return <>
        <Body persoenlicheBeratung={
            <PersoenlicheBeratung showContactFormLink={true}/>}>
            <div className='textblock'>
                <h2>{kontakt.danke.title}</h2>
                <p>{kontakt.danke.text}</p>
                <p>
                <span className='text-icon-link text-icon-link--link' onClick={() => push('/')}>
                    <span className='text-icon-link__icon'/>
                    <span className='text-icon-link__text'>{kontakt.danke.schliessenButtonText}</span>
                </span>
                </p>
            </div>
        </Body>
    </>;
};