import {BeratungInput} from '@juris/api-gen';

export const validate = (activeStep: number, input: BeratungInput): boolean => {
    switch (activeStep) {
        case 0:
            return !!input.branche?.length;
        case 1:
            return !!input.anzahlPersonen?.length;
        case 2:
            return !!input.nutzung;
        case 3:
            return true;
    }

    return true;
};