import React, {
    createContext,
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useCallback,
    useContext,
    useMemo,
    useState
} from 'react';
import {BeratungControllerImplApi, Configuration, KontaktControllerImplApi} from '@juris/api-gen';
import {config} from 'environment';
import {LoadingSpinner} from 'ui/_Shared';

const ApiContext = createContext<ApiContextModel>({
    apiObjects: {
        beratungController: new BeratungControllerImplApi(),
        kontaktController: new KontaktControllerImplApi()
    },
    apiConfiguration: new Configuration(),
    setApiConfiguration: () => undefined,
    defaultRequestErrorHandler: () => () => undefined
});

interface ApiContextModel {
    apiObjects: ApiObjects,
    apiConfiguration: Configuration,
    setApiConfiguration: Dispatch<SetStateAction<Configuration>>,
    defaultRequestErrorHandler: (headline?: string, body?: string) => (response: Response) => void
}

interface ApiObjects {
    beratungController: BeratungControllerImplApi;
    kontaktController: KontaktControllerImplApi;
}

export const ApiContextProvider: FunctionComponent = ({children}) => {
    const [activeRequests, setActiveRequests] = useState<number>(0);
    const [apiConfiguration, setApiConfiguration] = useState<Configuration>(new Configuration({
        basePath: `${config.basePath.replace(/\/+$/, '')}/${config.contextPath}`,
        middleware: [{
            pre: async (context) => {
                setActiveRequests(c => c + 1);
                return {
                    url: context.url,
                    init: {
                        ...context.init,
                        credentials: 'include'
                    }
                };
            },
            post: async () => setActiveRequests(c => Math.max(c - 1, 0))
        }]
    }));

    const apiObjects: ApiObjects = useMemo(() => ({
        beratungController: new BeratungControllerImplApi(apiConfiguration),
        kontaktController: new KontaktControllerImplApi(apiConfiguration)
    }), [apiConfiguration]);

    const errorResponseHandler = useCallback((response: Response, headline?: string, body?: string) => {
        setActiveRequests(c => Math.max(c - 1, 0));
        response.json().then((error: any) => console.log({
                headline: headline || 'Ein Fehler ist passiert',
                body: `${body || ''} Grund: ${error.message}`
            })
        );
    }, []);

    const defaultRequestErrorHandler = useCallback((headline?: string, body?: string) =>
        (response: Response) => errorResponseHandler(response, headline, body), [errorResponseHandler]);

    return (
        <ApiContext.Provider
            value={{apiObjects, apiConfiguration, setApiConfiguration, defaultRequestErrorHandler}}>
            {children}
            {!!activeRequests && <LoadingSpinner/>}
        </ApiContext.Provider>
    );
};

export const useApiContext = () => {
    const context = useContext(ApiContext);
    if (context === undefined) {
        throw new Error('useApiContextState must be used within a ApiContextProvider');
    }
    return context;
};