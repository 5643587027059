import React, {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import {useConfigContext} from 'Context';

export const Jumbotron: FunctionComponent = () => {
    const {ergebnis} = useConfigContext();

    return <section className='cs-jumbotron-teaser content-section'>
        <div className='container'>
            <div className='jumbotron-teaser'>
                <div className='jumbotron-teaser__row row'>
                    <div className='col-10 col-lg-7 offset-1'>
                        <div className='textblock textblock--text-large'>
                            <h1>{ergebnis.jumbotron.title}</h1>
                            <p dangerouslySetInnerHTML={{__html: ergebnis.jumbotron.text}}/>
                        </div>
                    </div>
                    <div className='col-12 col-md-11 col-lg-3 offset-lg-0'>
                        <div className='jumbotron-teaser__cta-wrapper'>
                            <div className='jumbotron-teaser__cta-wrapper--button'>
                                <Link className='cta-button cta-button--button-transparent cs-jumbotron-teaser'
                                      to={'/fragen/start'}>{ergebnis.jumbotron.bearbeitenLinkText}</Link>
                            </div>
                            <div className='jumbotron-teaser__cta-wrapper--link'>
                                <span className='text-icon-link text-icon-link--share' data-href={window.location.href}>
                                    <div className='share-overlay'>
                                        <div className='share-overlay__content'>
                                            <ul className='share-overlay__link-list'>
                                                <li className='share-overlay__link-item'>
                                                    <a href='whatsapp://send?text&#x3D;'
                                                       className='text-icon-link text-icon-link--whatsapp'>
                                                        <span className='text-icon-link__icon'/>
                                                        <span className='text-icon-link__text'>WhatsApp</span>
                                                    </a>
                                                </li>
                                                <li className='share-overlay__link-item'>
                                                    <a href='mailto:?&amp;subject&#x3D;&amp;body&#x3D;'
                                                       className='text-icon-link text-icon-link--email'>
                                                        <span className='text-icon-link__icon'/>
                                                        <span className='text-icon-link__text'>E-Mail</span>
                                                    </a>
                                                </li>
                                                <li className='share-overlay__link-item'>
                                                    <a href='https://www.facebook.com/sharer/sharer.php?u&#x3D;'
                                                       className='text-icon-link text-icon-link--facebook'>
                                                        <span className='text-icon-link__icon'/>
                                                        <span className='text-icon-link__text'>facebook</span>
                                                    </a>
                                                </li>
                                            </ul>
                                            <span className='share-overlay__text-link'/>
                                            <div className='share-overlay__button' data-success='Kopiert!'>
                                                <button className='cta-button cta-button--button-white'
                                                        type='button'>Link kopieren</button>
                                            </div>
                                        </div>
                                    </div> <span className='text-icon-link__icon'/>
                                    <span className='text-icon-link__text'>{ergebnis.jumbotron.teilenLinkText}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;
};