import React, {FunctionComponent} from 'react';
import {Body, PersoenlicheBeratung} from 'ui/_Shared';
import {KontaktHeader} from './KontaktHeader';
import {KontaktForm} from './KontaktForm';

interface KontaktProps {
    variant?: string;
}

export const Kontakt: FunctionComponent<KontaktProps> = ({variant}) => {
    return (
        <Body persoenlicheBeratung={
            <PersoenlicheBeratung showContactFormLink={false}/>} bottomContainer={<KontaktForm/>}>
            <KontaktHeader variant={variant}/>
        </Body>
    );
};