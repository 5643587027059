import React, {FunctionComponent} from 'react';

export const LoadingSpinner: FunctionComponent<{ label?: string }> = ({label}) => (
    <div style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        zIndex: 100001
    }}/>
);
