import React, {FunctionComponent} from 'react';
import {useConfigContext, useInputContext} from 'Context';
import {MultiSelect, Select} from 'ui/_Shared';
import {Fachanwaltschaft} from './Fachanwaltschaft';
import {DavMitgliedschaft} from './DavMitgliedschaft';
import {NotarielleLeistungen} from './NotarielleLeistungen';
import {AnwaltlicheLeistungen} from './AnwaltlicheLeistungen';

export const Arbeitsumfeld: FunctionComponent = () => {
    const {input, setInput} = useInputContext();
    const {seite2} = useConfigContext();

    return <>
        <div className='textblock'>
            <h2>{seite2.title}</h2>
        </div>
        <Select label={seite2.frage1.label}
                value={input.anzahlPersonen || ''}
                onChangeHandler={value => setInput(input => ({...input, anzahlPersonen: value as string}))}
                options={seite2.frage1.options}/>

        {input.branche === 'anwalt' && <>
            <Fachanwaltschaft/>
            <DavMitgliedschaft/>
            <NotarielleLeistungen/>
        </>}
        {['notar', 'stbwp'].includes(input.branche || '') && <>
            <AnwaltlicheLeistungen/>
            {input.anwaeltlicheLeistungen && <>
                <Fachanwaltschaft/>
                <DavMitgliedschaft/>
            </>}
        </>}
        {input.branche === 'unternehmen' && <>
            <MultiSelect label={seite2.frage6.label}
                         helperText={seite2.frage6.helperText}
                         value={input.bereiche || []}
                         onChangeHandler={val => setInput(input => ({...input, bereiche: val as Array<string>}))}
                         options={seite2.frage6.options}/>
        </>}
    </>;
};