import React, {FunctionComponent} from 'react';
import {useConfigContext} from 'Context';

interface KontaktHeaderProps {
    variant?: string;
}

export const KontaktHeader: FunctionComponent<KontaktHeaderProps> = ({variant}) => {
    const {kontakt} = useConfigContext();
    const headerIndex = (!!variant && ['hochschule', 'student'].includes(variant))
        ? variant as 'hochschule' | 'student'
        : 'allgemein';
    console.log(variant, headerIndex);
    return (
        <div className='textblock'>
            <h2>{kontakt.header[headerIndex].title}</h2>
            <div dangerouslySetInnerHTML={{__html: kontakt.header[headerIndex].text}}/>
        </div>
    );
};