import React, {createContext, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';

const StepperContext = createContext<StepperContextModel>({
    activeStep: 0,
    showNextStep: () => undefined,
    showPrevStep: () => undefined,
    jumpToFirstStep: () => undefined,
    jumpToLastStep: () => undefined,
    showByIndex: () => undefined
});

interface StepperContextModel {
    activeStep: number;
    jumpToFirstStep: () => void;
    jumpToLastStep: () => void;
    showNextStep: () => void;
    showPrevStep: () => void;
    showByIndex: (index: number) => void;
}

export const StepperContextProvider: FunctionComponent = ({children}) => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const {push, location} = useHistory();

    const jumpToLastStep = useCallback(() => setActiveStep(4), [setActiveStep]);
    const jumpToFirstStep = useCallback(() => setActiveStep(0), [setActiveStep]);
    const showNextStep = useCallback(() => setActiveStep(value => value + 1), [setActiveStep]);
    const showPrevStep = useCallback(() => setActiveStep(value => value - 1), [setActiveStep]);
    const showByIndex = useCallback((index: number) => setActiveStep(index), [setActiveStep]);

    const routes = useMemo(() => ['/fragen/branche', '/fragen/arbeitsumfeld', '/fragen/themenschwerpunkte', '/fragen/highlighttitel', '/ergebnis'], []);

    useEffect(() => {
        if (location.pathname !== routes[activeStep]) {
            push(routes[activeStep]);
        }
    }, [location, push, routes, activeStep]);

    return <StepperContext.Provider value={{
        activeStep,
        jumpToFirstStep,
        jumpToLastStep,
        showNextStep,
        showPrevStep,
        showByIndex
    }}>{children}</StepperContext.Provider>;
};

export const useStepperContext = () => {
    const context = useContext(StepperContext);
    if (context === undefined) {
        throw new Error('useStepperContext must be used within a StepperContextProvider');
    }
    return context;
};