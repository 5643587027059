import React, {FunctionComponent} from 'react';
import {ProduktGruppe as ProduktGruppeModel} from '@juris/api-gen';
import {ProduktGruppe} from './_Shared';
import {useConfigContext} from 'Context';

interface SmarteLoesungenProps {
    smarteLoesungen: Array<ProduktGruppeModel>;
}

export const SmarteLoesungen: FunctionComponent<SmarteLoesungenProps> = ({smarteLoesungen}) => {
    const {ergebnis} = useConfigContext();

    return <>
        <section className='cs-textblock content-section'>
            <div className='container'>
                <div className='textblock textblock--text-large'>
                    <h2>{ergebnis.smarteLoesungen.title}</h2>
                    <p dangerouslySetInnerHTML={{__html: ergebnis.smarteLoesungen.title}}/>
                </div>
            </div>
        </section>
        <section className='cs-expertise-container content-section in-viewport'>
            <div className='expertise-container' data-show-more='alle anzeigen' data-show-less='weniger anzeigen'
                 data-expand-all='alle aufklappen' data-expand-none='alle einklappen'
                 data-load-all='alle Fachgebiete laden' data-load-less='weniger Fachgebiete anzeigen'>
                <div className='container'>
                    <div className='row'>
                        {smarteLoesungen.map((item, index) => <ProduktGruppe key={index}
                                                                             index={index}
                                                                             produktGruppe={item || {}}/>)}
                    </div>
                </div>
            </div>
        </section>
    </>;
};