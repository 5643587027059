/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {BeratungInput, BeratungInputToJSON, ProduktEmpfehlung, ProduktEmpfehlungFromJSON} from '../models';

export interface ErmittleHighlightTitelUsingPOSTRequest {
    beratungInput: BeratungInput;
}

export interface ErmittleProduktEmpfehlungUsingPOSTRequest {
    beratungInput: BeratungInput;
}

/**
 * 
 */
export class BeratungControllerImplApi extends runtime.BaseAPI {

    /**
     * ermittleHighlightTitel
     */
    async ermittleHighlightTitelUsingPOSTRaw(requestParameters: ErmittleHighlightTitelUsingPOSTRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.beratungInput === null || requestParameters.beratungInput === undefined) {
            throw new runtime.RequiredError('beratungInput','Required parameter requestParameters.beratungInput was null or undefined when calling ermittleHighlightTitelUsingPOST.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest/beratung/ermittleHighlightTitel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BeratungInputToJSON(requestParameters.beratungInput),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * ermittleHighlightTitel
     */
    async ermittleHighlightTitelUsingPOST(requestParameters: ErmittleHighlightTitelUsingPOSTRequest): Promise<Array<string>> {
        const response = await this.ermittleHighlightTitelUsingPOSTRaw(requestParameters);
        return await response.value();
    }

    /**
     * ermittleProduktEmpfehlung
     */
    async ermittleProduktEmpfehlungUsingPOSTRaw(requestParameters: ErmittleProduktEmpfehlungUsingPOSTRequest): Promise<runtime.ApiResponse<ProduktEmpfehlung>> {
        if (requestParameters.beratungInput === null || requestParameters.beratungInput === undefined) {
            throw new runtime.RequiredError('beratungInput','Required parameter requestParameters.beratungInput was null or undefined when calling ermittleProduktEmpfehlungUsingPOST.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest/beratung/ermittleBeratung`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BeratungInputToJSON(requestParameters.beratungInput),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProduktEmpfehlungFromJSON(jsonValue));
    }

    /**
     * ermittleProduktEmpfehlung
     */
    async ermittleProduktEmpfehlungUsingPOST(requestParameters: ErmittleProduktEmpfehlungUsingPOSTRequest): Promise<ProduktEmpfehlung> {
        const response = await this.ermittleProduktEmpfehlungUsingPOSTRaw(requestParameters);
        return await response.value();
    }

}
