import React, {FunctionComponent, ReactElement} from 'react';

interface BodyProps {
    persoenlicheBeratung: ReactElement;
    hinweis?: string;
    bottomContainer?: JSX.Element;
}

export const Body: FunctionComponent<BodyProps> = ({children, persoenlicheBeratung, hinweis, bottomContainer}) => {
    return (
        <div className='advisory-tool__body'>
            <div className='container'>
                <div className='advisory-tool__grid advisory-tool__grid--full-width'>
                    <div className='advisory-tool__body-left'>{children}</div>
                    <div className='advisory-tool__body-right-top'>
                        <div className='advisory-tool__content-box advisory-tool__content-box--light'>
                            {persoenlicheBeratung}
                        </div>
                    </div>
                    {!!hinweis && <div className='advisory-tool__body-right-bottom'>
                        <div className='advisory-tool__content-box advisory-tool__content-box--info'>
                            <div className='textblock' dangerouslySetInnerHTML={{__html: hinweis || ''}}/>
                        </div>
                    </div>}
                </div>
            </div>
            {bottomContainer}
        </div>
    );
};