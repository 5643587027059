import React, {FunctionComponent, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useConfigContext, useStepperContext} from 'Context';

interface HeaderProps {
}

export const Header: FunctionComponent<HeaderProps> = () => {
    const {jumpToFirstStep} = useStepperContext();
    const {header} = useConfigContext();
    const {push} = useHistory();

    const closeHandler = useCallback(() => {
        jumpToFirstStep();
        push('/');
    }, [jumpToFirstStep, push]);

    return (
        <div className='advisory-tool__header'>
            <div className='advisory-tool__header-container'>
                <div className='advisory-tool__title'>{header.title}</div>
                <button className='advisory-tool__close'
                        title='Schließen'
                        onClick={closeHandler}>
                    <img className='advisory-tool__close-icon'
                         src={header.closeIcon}
                         alt='X'/>
                </button>
            </div>
        </div>
    );
};