import React, {FunctionComponent} from 'react';
import {ProduktKarte as ProduktKarteModel} from '@juris/api-gen';
import {useInputContext} from 'Context';

interface BasisProduktkarteProps extends ProduktKarteModel {
}

export const BasisProduktKarte: FunctionComponent<BasisProduktkarteProps> = ({
                                                                                 link,
                                                                                 imageSource,
                                                                                 imageAlt,
                                                                                 title,
                                                                                 highlightTitelList
                                                                             }) => {
    const {input} = useInputContext();

    const renderOptionalHighlightTitelList = () => {
        const selectedHighlightTitelInProdukt = highlightTitelList?.reduce<Array<string>>(
            (prev, cur) => !!input.highlightTitel?.includes(cur) ? [...prev, cur] : prev, []);

        return !!selectedHighlightTitelInProdukt?.length && <div className='product-card__highlights'>
            inkl. {selectedHighlightTitelInProdukt.join(', ')}
        </div>;
    };

    return (
        <article className='small-product-card small-product-card--shadow col-md-2' style={{marginBottom: '32px'}}>
            <a href={link || ''} title={title || ''} target='_blank' rel='noreferrer'>
                <div className='small-product-card__body'>
                    <div className='small-product-card__visual'>
                        <img src={imageSource || ''} alt={imageAlt || ''}/>
                    </div>
                    <p className='small-product-card__description'>
                        {title || ''}
                    </p>
                    {renderOptionalHighlightTitelList()}
                </div>
            </a>
        </article>
    );
};