import React, {FunctionComponent, useEffect, useState} from 'react';
import {ProduktEmpfehlung} from '@juris/api-gen';
import {useApiContext, useInputContext} from 'Context';
import {Jumbotron} from './Jumbotron';
import {BasisProdukte} from './BasisProdukte';
import {Vertiefung} from './Vertiefung';
import {PlusOption} from './PlusOption';
import {SmarteLoesungen} from './SmarteLoesungen';
import {KontaktStoerer} from './KontaktStoerer';

interface ErgebnisseiteProps {
    encodedInput: string;
}

export const Ergebnisseite: FunctionComponent<ErgebnisseiteProps> = ({encodedInput}) => {
    const {apiObjects} = useApiContext();
    const {setInput} = useInputContext();
    const [produktEmpfehlung, setProduktEmpfehlung] = useState<ProduktEmpfehlung>({});

    useEffect(() => {
        const beratungFromUrl = JSON.parse(atob(decodeURIComponent(encodedInput)));
        apiObjects.beratungController
            .ermittleProduktEmpfehlungUsingPOST({beratungInput: beratungFromUrl})
            .then(value => {
                setProduktEmpfehlung(value);
                setInput(beratungFromUrl);
            });
    }, [setProduktEmpfehlung, setInput, encodedInput, apiObjects.beratungController]);

    return <>
        <Jumbotron/>
        <BasisProdukte basis={produktEmpfehlung.basis || {}}/>
        {!!produktEmpfehlung.themen?.length && <Vertiefung themen={produktEmpfehlung.themen || []}/>}
        <PlusOption/>
        {!!produktEmpfehlung.smarteLoesungen?.length &&
            <SmarteLoesungen smarteLoesungen={produktEmpfehlung.smarteLoesungen || []}/>}
        <KontaktStoerer/>
    </>;
};