import React, {FunctionComponent, ReactText} from 'react';

interface RadioProps {
    label: string;
    helperText?: string;
    value: ReactText;
    onChangeHandler: (value: ReactText) => void;
    options: Array<{ label: string, value: ReactText }>;
    buttonSize?: 'slim' | 'large';
}

export const Radio: FunctionComponent<RadioProps> = ({
                                                         label,
                                                         helperText,
                                                         value,
                                                         onChangeHandler,
                                                         options,
                                                         buttonSize = 'slim'
                                                     }) => {
    return options.length
        ? <>
            <label className='advisory-tool__form-label'>{label}</label>
            <div className='advisory-tool__button-wrapper'>
                {!!helperText && <p>{helperText}</p>}
                <div className='row'>
                    {options.map((option, index) =>
                        <div className='col-sm-6 col-lg-3'
                             key={index}
                             onClick={() => onChangeHandler(option.value)}>
                            <div
                                className={['advisory-tool__button-rectangle', `advisory-tool__button-rectangle--${buttonSize}`,
                                    option.value === value ? 'advisory-tool__button-rectangle--active' : ''].join(' ')}>
                                <p><strong>{option.label}</strong></p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
        : null;
};