/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {
    BasisGruppe,
    BasisGruppeFromJSON,
    BasisGruppeToJSON,
    ProduktGruppe,
    ProduktGruppeFromJSON,
    ProduktGruppeToJSON
} from './';

/**
 * 
 * @export
 * @interface ProduktEmpfehlung
 */
export interface ProduktEmpfehlung {
    /**
     * 
     * @type {BasisGruppe}
     * @memberof ProduktEmpfehlung
     */
    basis?: BasisGruppe;
    /**
     * 
     * @type {Array<ProduktGruppe>}
     * @memberof ProduktEmpfehlung
     */
    smarteLoesungen?: Array<ProduktGruppe>;
    /**
     * 
     * @type {Array<ProduktGruppe>}
     * @memberof ProduktEmpfehlung
     */
    themen?: Array<ProduktGruppe>;
}

export function ProduktEmpfehlungFromJSON(json: any): ProduktEmpfehlung {
    return ProduktEmpfehlungFromJSONTyped(json, false);
}

export function ProduktEmpfehlungFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProduktEmpfehlung {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'basis': !exists(json, 'basis') ? undefined : BasisGruppeFromJSON(json['basis']),
        'smarteLoesungen': !exists(json, 'smarteLoesungen') ? undefined : ((json['smarteLoesungen'] as Array<any>).map(ProduktGruppeFromJSON)),
        'themen': !exists(json, 'themen') ? undefined : ((json['themen'] as Array<any>).map(ProduktGruppeFromJSON)),
    };
}

export function ProduktEmpfehlungToJSON(value?: ProduktEmpfehlung | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'basis': BasisGruppeToJSON(value.basis),
        'smarteLoesungen': value.smarteLoesungen === undefined ? undefined : ((value.smarteLoesungen as Array<any>).map(ProduktGruppeToJSON)),
        'themen': value.themen === undefined ? undefined : ((value.themen as Array<any>).map(ProduktGruppeToJSON)),
    };
}


