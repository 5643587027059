import React, {FunctionComponent, ReactText} from 'react';

interface SelectProps {
    label: string;
    helperText?: string;
    errorState?: boolean;
    value: ReactText;
    onChangeHandler: (value: ReactText) => void;
    options: Array<{ label: string, value: ReactText }>;
}

export const Select: FunctionComponent<SelectProps> = ({
                                                           label,
                                                           helperText,
                                                           errorState,
                                                           value,
                                                           onChangeHandler,
                                                           options
                                                       }) => {
    return options.length
        ? <>
            <label className='advisory-tool__form-label'>{label}</label>
            {!!helperText && <span>{helperText}</span>}
            <div className='advisory-tool__select-wrapper'>
                <select
                    className={['advisory-tool__form-element', 'advisory-tool__select', errorState ? 'advisory-tool__select--invalid' : ''].join(' ')}
                    onChange={e => onChangeHandler(e.target.value)}>
                    {options.map((option, index) => (
                        <option key={index}
                                value={option.value}
                                selected={value === option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
        </>
        : null;
};