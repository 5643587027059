/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface ProduktKarte
 */
export interface ProduktKarte {
    /**
     * 
     * @type {string}
     * @memberof ProduktKarte
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProduktKarte
     */
    highlightTitelList?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProduktKarte
     */
    imageAlt?: string;
    /**
     * 
     * @type {string}
     * @memberof ProduktKarte
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof ProduktKarte
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof ProduktKarte
     */
    title?: string;
}

export function ProduktKarteFromJSON(json: any): ProduktKarte {
    return ProduktKarteFromJSONTyped(json, false);
}

export function ProduktKarteFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProduktKarte {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'highlightTitelList': !exists(json, 'highlightTitelList') ? undefined : json['highlightTitelList'],
        'imageAlt': !exists(json, 'imageAlt') ? undefined : json['imageAlt'],
        'imageSource': !exists(json, 'imageSource') ? undefined : json['imageSource'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function ProduktKarteToJSON(value?: ProduktKarte | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'highlightTitelList': value.highlightTitelList,
        'imageAlt': value.imageAlt,
        'imageSource': value.imageSource,
        'link': value.link,
        'title': value.title,
    };
}


