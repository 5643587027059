import React, {FunctionComponent} from 'react';
import {ProduktKarte as ProduktKarteModel} from '@juris/api-gen';
import {useInputContext} from '../../../Context';

interface ProduktKarteProps extends ProduktKarteModel {
    isFirst: boolean;
}

export const ProduktKarte: FunctionComponent<ProduktKarteProps> = ({
                                                                       isFirst,
                                                                       title,
                                                                       imageAlt,
                                                                       imageSource,
                                                                       link,
                                                                       highlightTitelList
                                                                   }) => {
    const {input} = useInputContext();

    const renderOptionalHighlightTitelList = () => {
        const selectedHighlightTitelInProdukt = highlightTitelList?.reduce<Array<string>>(
            (prev, cur) => !!input.highlightTitel?.includes(cur) ? [...prev, cur] : prev, []);

        return !!selectedHighlightTitelInProdukt?.length && <div className='product-card__highlights'>
            inkl. {selectedHighlightTitelInProdukt.join(', ')}
        </div>;
    };

    return (
        <div
            className={['expertise-container__item-col', 'col-4', 'col-md-2', 'col-lg-4', ...isFirst ? ['offset-md-1', 'offset-lg-0'] : []]
                .join(' ')}>
            <a href={link || ''} title={title || ''} target='_blank' rel='noreferrer'>
                <div className='product-card'>
                    <div className='product-card__image-container'>
                        <img className='product-card__image' src={imageSource || ''} alt={imageAlt || ''}/>
                    </div>
                    <div className='product-card__text'>{title || ''}</div>
                    {renderOptionalHighlightTitelList()}
                </div>
            </a>
        </div>
    );
};