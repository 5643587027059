import React, {FunctionComponent} from 'react';
import {useHistory} from 'react-router-dom';
import {validate} from 'utils';
import {useConfigContext, useInputContext, useStepperContext} from 'Context';

export const Stepper: FunctionComponent = () => {
    const {input} = useInputContext();
    const {push} = useHistory();
    const {activeStep, showPrevStep, showNextStep, showByIndex} = useStepperContext();
    const activeStepValid = validate(activeStep, input);
    const {stepper} = useConfigContext();

    const backHandler = (e: React.MouseEvent) => {
        e.preventDefault();

        if (activeStep === 2 && ['verwaltung', 'verband'].includes(input.branche || '')) {
            showByIndex(0);
        } else {
            showPrevStep();
        }
    };

    const continueHandler = (e: React.MouseEvent) => {
        e.preventDefault();

        if (validate(activeStep, input)) {
            if (activeStep === 0) {
                if (['verwaltung', 'verband'].includes(input.branche || '')) {
                    showByIndex(2);
                    return;
                } else if (['student', 'hochschule'].includes(input.branche || '')) {
                    push(`/kontakt/${input.branche}`);
                    return;
                }
            }

            if (activeStep === 3) {
                window.location.href = `${stepper.linkToBeratungstoolPage}#/ergebnis/${encodeURIComponent(btoa(JSON.stringify(input)))}`;
                return;
            }

            showNextStep();
        }
    };

    return (
        <div className='advisory-tool__footer'>
            <div className='container'>
                <div className='advisory-tool__navigation-wrapper'>
                    <nav className='advisory-tool__navigation-inner'>
                        {activeStep > 0 &&
                            <button onClick={backHandler}
                                    className='advisory-tool__nav-buttons--prev'>{stepper.zurueckButtonText}</button>}
                        <ul className='advisory-tool__nav-bullets'>
                            <li className={['advisory-tool__bullet', activeStep >= 0 ? 'advisory-tool__bullet--active' : ''].join(' ')}/>
                            {(activeStep === 0 || ['anwalt', 'notar', 'stbwp', 'unternehmen'].includes(input.branche || '')) &&
                                <li className={['advisory-tool__bullet', activeStep >= 1 ? 'advisory-tool__bullet--active' : ''].join(' ')}/>}
                            <li className={['advisory-tool__bullet', activeStep >= 2 ? 'advisory-tool__bullet--active' : ''].join(' ')}/>
                            <li className={['advisory-tool__bullet', activeStep >= 3 ? 'advisory-tool__bullet--active' : ''].join(' ')}/>
                        </ul>
                        {activeStep < 4 &&
                            <button onClick={continueHandler}
                                    className={[
                                        'advisory-tool__nav-buttons--next',
                                        !activeStepValid ? 'advisory-tool__nav-buttons--disabled' : ''
                                    ].join(' ')}>{stepper.weiterButtonText}</button>}
                    </nav>
                </div>
            </div>
        </div>
    );
};