/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {KontaktAnfrage, KontaktAnfrageToJSON} from '../models';

export interface SendeKontaktAnfrageUsingPOSTRequest {
    kontaktAnfrage: KontaktAnfrage;
}

/**
 * 
 */
export class KontaktControllerImplApi extends runtime.BaseAPI {

    /**
     * sendeKontaktAnfrage
     */
    async sendeKontaktAnfrageUsingPOSTRaw(requestParameters: SendeKontaktAnfrageUsingPOSTRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.kontaktAnfrage === null || requestParameters.kontaktAnfrage === undefined) {
            throw new runtime.RequiredError('kontaktAnfrage','Required parameter requestParameters.kontaktAnfrage was null or undefined when calling sendeKontaktAnfrageUsingPOST.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest/kontakt/sendeKontaktanfrage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KontaktAnfrageToJSON(requestParameters.kontaktAnfrage),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * sendeKontaktAnfrage
     */
    async sendeKontaktAnfrageUsingPOST(requestParameters: SendeKontaktAnfrageUsingPOSTRequest): Promise<string> {
        const response = await this.sendeKontaktAnfrageUsingPOSTRaw(requestParameters);
        return await response.value();
    }

}
