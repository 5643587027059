import React, {FunctionComponent} from 'react';
import {useConfigContext} from 'Context';

export const KontaktStoerer: FunctionComponent = () => {
    const {ergebnis} = useConfigContext();

    return <section
        className='cs-product-teaser content-section content-section--background content-section--lavender in-viewport'>
        <div className='container'>
            <div className='figure-text'>
                <div className='figure-text__container'>
                    <div className='figure-text__row row'>
                        <div className='figure-text__col col-sm-12 col-md-6 col-lg-4'>
                            <div className='figure-text__visual'>
                                <figure className='figure figure__tlr--small cs-product-teaser'>
                                    <picture className='picture'>
                                        <img className='picture'
                                             alt={ergebnis.kontaktStoerer.imageAlt}
                                             src={ergebnis.kontaktStoerer.imageSource}/>
                                    </picture>
                                </figure>
                            </div>
                        </div>
                        <div className='figure-text__col col-sm-12 col-md-6 col-lg-7 offset-lg-1'>
                            <div className='textblock'
                                 dangerouslySetInnerHTML={{__html: ergebnis.kontaktStoerer.textblock}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;
};