import React, {FunctionComponent} from 'react';
import {useConfigContext} from 'Context';

export const PlusOption: FunctionComponent = () => {
    const {ergebnis} = useConfigContext();

    return <section className='cs-text-cta-text content-section content-section--background content-section--lavender'>
        <div className='container'>
            <div className='text-cta-text'>
                <div className='row'>
                    <div className='col-lg-10 offset-lg-1'>
                        <div className='textblock'>
                            <h2>{ergebnis.plusOption.title}</h2>
                        </div>
                        <div className='call-to-action-tsr'>
                            <div className='row'>
                                <div className='col-12 col-md-7'>
                                    <p className='call-to-action-tsr__text'
                                       dangerouslySetInnerHTML={{__html: ergebnis.plusOption.text}}/>
                                </div>
                                <div className='col-12 col-md-4 offset-md-1'>
                                    <div className='call-to-action-tsr__button'>
                                        <a className='cta-button cta-button--button-transparent'
                                           target='_blank'
                                           rel='noreferrer'
                                           href={ergebnis.plusOption.linkTarget}>{ergebnis.plusOption.linkText}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;
};