import React, {useMemo} from 'react';
import {Route, Switch} from 'react-router-dom';
import {StepperContextProvider, useConfigContext} from 'Context';
import {Body, Layer, PersoenlicheBeratung} from 'ui/_Shared';
import {Arbeitsumfeld, HighlightTitel, Start, Themenschwergebiete} from './Fragenstrecke';
import {Danke, Kontakt} from './Kontakt';
import {Teaser} from './Teaser';
import {Ergebnisseite} from './Ergebnisseite';

export const AppRouter = () => {
    const {seite2, seite3} = useConfigContext();
    const fragenStreckePersoenlicheBeratung = useMemo(() => (
        <PersoenlicheBeratung showContactFormLink={true}/>
    ), []);

    return (
        <Switch>
            <Route exact path='/'>
                <Teaser/>
            </Route>
            <Route path='/fragen'>
                <StepperContextProvider>
                    <Layer withStepper={true}>
                        <Route exact path='/fragen/branche'>
                            <Body persoenlicheBeratung={fragenStreckePersoenlicheBeratung}>
                                <Start/>
                            </Body>
                        </Route>
                        <Route exact path='/fragen/arbeitsumfeld'>
                            <Body persoenlicheBeratung={fragenStreckePersoenlicheBeratung}
                                  hinweis={seite2.hinweisText}>
                                <Arbeitsumfeld/>
                            </Body>
                        </Route>
                        <Route exact path='/fragen/themenschwerpunkte'>
                            <Body persoenlicheBeratung={fragenStreckePersoenlicheBeratung}
                                  hinweis={seite3.hinweisText}>
                                <Themenschwergebiete/>
                            </Body>
                        </Route>
                        <Route exact path='/fragen/highlighttitel'>
                            <Body persoenlicheBeratung={fragenStreckePersoenlicheBeratung}>
                                <HighlightTitel/>
                            </Body>
                        </Route>
                    </Layer>
                </StepperContextProvider>
            </Route>
            <Route exact path='/ergebnis/:input'>
                {({match}) => (
                    <Ergebnisseite encodedInput={match?.params.input || ''}/>
                )}
            </Route>
            <Route exact path='/kontakt/danke'>
                <Layer>
                    <Danke/>
                </Layer>
            </Route>
            <Route exact path='/kontakt/:variant?'>
                {({match}) => (
                    <Layer>
                        <Kontakt variant={match?.params.variant}/>
                    </Layer>
                )}
            </Route>
        </Switch>
    );
};