import React, {FunctionComponent} from 'react';
import {useConfigContext, useInputContext} from 'Context';
import {Radio} from 'ui/_Shared';

export const NotarielleLeistungen: FunctionComponent = () => {
    const {input, setInput} = useInputContext();
    const {seite2} = useConfigContext();

    return <Radio label={seite2.frage5.label}
                  value={!!input.notarielleLeistungen ? 'Ja' : 'Nein'}
                  onChangeHandler={value => setInput(input => ({...input, notarielleLeistungen: value === 'Ja'}))}
                  options={[{label: 'Ja', value: 'Ja'}, {label: 'Nein', value: 'Nein'}]}/>;
};