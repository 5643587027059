import React, {FunctionComponent, HTMLInputTypeAttribute, ReactText, useEffect, useState} from 'react';

interface KontaktFormInputProps {
    id: string;
    label: string;
    placeholder: string;
    value: ReactText;
    onChangeHandler: (value: ReactText) => void;
    errorState?: boolean;
    errorMessage?: string;
    type?: HTMLInputTypeAttribute;
    withFormRow?: boolean;
    additionalCssClass?: string;
}

export const KontaktFormInput: FunctionComponent<KontaktFormInputProps> = ({
                                                                               id,
                                                                               label,
                                                                               placeholder,
                                                                               value,
                                                                               onChangeHandler,
                                                                               errorMessage,
                                                                               errorState,
                                                                               type = 'text',
                                                                               withFormRow = true,
                                                                               additionalCssClass = ''
                                                                           }) => {
    const [touched, setTouched] = useState<boolean>(false);

    useEffect(() => {
        setTouched(false);
    }, [errorState, value]);

    const renderInput = () => (
        <div className={['form__col', 'col', 'col-12', additionalCssClass].join(' ')}>
            <div className={['form__field', (!touched && errorState) ? 'form__field--error' : ''].join(' ')}>
                <div className='text-input'>
                    <div className='text-input__inner'>
                        <div className='text-input__content'>
                            <input id={id}
                                   className={['text-input__element', !!value ? 'text-input__element--filled' : ''].join(' ')}
                                   type={type}
                                   value={value}
                                   onChange={e => onChangeHandler(e.target.value)}
                                   placeholder={placeholder}
                                   onFocus={() => setTouched(true)}/>
                            <label className='text-input__label' htmlFor={id}>
                                <span className='text-input__label-text'>{label}</span>
                            </label>
                        </div>
                    </div>
                </div>
                {errorMessage && <div className='form__field-error-message'>{errorMessage}</div>}
            </div>
        </div>
    );

    return withFormRow ? <div className='form__row row'>{renderInput()}</div> : renderInput();
};