/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {ProduktKarte, ProduktKarteFromJSON, ProduktKarteToJSON} from './';

/**
 * 
 * @export
 * @interface BasisGruppe
 */
export interface BasisGruppe {
    /**
     * 
     * @type {string}
     * @memberof BasisGruppe
     */
    branche?: string;
    /**
     * 
     * @type {Array<ProduktKarte>}
     * @memberof BasisGruppe
     */
    produkte?: Array<ProduktKarte>;
}

export function BasisGruppeFromJSON(json: any): BasisGruppe {
    return BasisGruppeFromJSONTyped(json, false);
}

export function BasisGruppeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasisGruppe {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'branche': !exists(json, 'branche') ? undefined : json['branche'],
        'produkte': !exists(json, 'produkte') ? undefined : ((json['produkte'] as Array<any>).map(ProduktKarteFromJSON)),
    };
}

export function BasisGruppeToJSON(value?: BasisGruppe | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'branche': value.branche,
        'produkte': value.produkte === undefined ? undefined : ((value.produkte as Array<any>).map(ProduktKarteToJSON)),
    };
}


