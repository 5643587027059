import React, {FunctionComponent, ReactText} from 'react';

interface RatingProps {
    label: string;
    helperText?: string;
    value: ReactText;
    onChangeHandler: (value: ReactText) => void;
    options: Array<ReactText>;
    buttonSize?: 'slim' | 'large';
    labelMin: string;
    labelMax: string;
}

export const Rating: FunctionComponent<RatingProps> = ({
                                                           label,
                                                           helperText,
                                                           value,
                                                           onChangeHandler,
                                                           options,
                                                           labelMin,
                                                           labelMax,
                                                           buttonSize = 'large'
                                                       }) => {
    return (
        <>
            <label className='advisory-tool__form-label'>{label}</label>
            <div className='advisory-tool__rating-wrapper'>
                {!!helperText && <p>{helperText}</p>}
                <ul className='advisory-tool__nav-bullets advisory-tool__nav-bullets--full-width'>
                    {options.map((option, index) => <li
                        key={index}
                        onClick={() => onChangeHandler(option)}
                        className={['advisory-tool__bullet', `advisory-tool__bullet--${buttonSize}`,
                            option === value ? 'advisory-tool__bullet--active' : ''].join(' ')}/>)}
                </ul>
                <div className='row'>
                    <div className='col-sm-4'>
                        <div className='advisory-tool__rating-wrapper--text-left'>{labelMin}</div>
                    </div>
                    <div className='col-sm-4 offset-sm-4'>
                        <div className='advisory-tool__rating-wrapper--text-right'>{labelMax}</div>
                    </div>
                </div>
            </div>
        </>
    );
};