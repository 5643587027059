import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from 'reportWebVitals';
import {ContextAggregate} from 'Context';
import {App} from './App';

const rootElement = document.getElementById('beratungstool');

ReactDOM.render(
    <React.StrictMode>
        <ContextAggregate>
            <App/>
        </ContextAggregate>
    </React.StrictMode>,
    rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
