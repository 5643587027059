/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProduktKonfiguration,
    ProduktKonfigurationFromJSON,
    ProduktKonfigurationVersionInfo,
    ProduktKonfigurationVersionInfoFromJSON
} from '../models';

export interface ActivateVersionUsingPOSTRequest {
    version?: number;
}

export interface AddNewProductConfigUsingPOSTRequest {
    configFile?: Blob;
}

export interface ExportConfigVersionUsingGETRequest {
    id: number;
}

/**
 * 
 */
export class AdminControllerImplApi extends runtime.BaseAPI {

    /**
     * activateVersion
     */
    async activateVersionUsingPOSTRaw(requestParameters: ActivateVersionUsingPOSTRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.version !== undefined) {
            queryParameters['version'] = requestParameters.version;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/admin/versions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * activateVersion
     */
    async activateVersionUsingPOST(requestParameters: ActivateVersionUsingPOSTRequest): Promise<string> {
        const response = await this.activateVersionUsingPOSTRaw(requestParameters);
        return await response.value();
    }

    /**
     * addNewProductConfig
     */
    async addNewProductConfigUsingPOSTRaw(requestParameters: AddNewProductConfigUsingPOSTRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.configFile !== undefined) {
            formParams.append('configFile', requestParameters.configFile as any);
        }

        const response = await this.request({
            path: `/rest/admin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * addNewProductConfig
     */
    async addNewProductConfigUsingPOST(requestParameters: AddNewProductConfigUsingPOSTRequest): Promise<string> {
        const response = await this.addNewProductConfigUsingPOSTRaw(requestParameters);
        return await response.value();
    }

    /**
     * exportConfigVersion
     */
    async exportConfigVersionUsingGETRaw(requestParameters: ExportConfigVersionUsingGETRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling exportConfigVersionUsingGET.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/admin/versions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * exportConfigVersion
     */
    async exportConfigVersionUsingGET(requestParameters: ExportConfigVersionUsingGETRequest): Promise<Blob> {
        const response = await this.exportConfigVersionUsingGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * getActiveVersion
     */
    async getActiveVersionUsingGETRaw(): Promise<runtime.ApiResponse<ProduktKonfigurationVersionInfo>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/admin/versions/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProduktKonfigurationVersionInfoFromJSON(jsonValue));
    }

    /**
     * getActiveVersion
     */
    async getActiveVersionUsingGET(): Promise<ProduktKonfigurationVersionInfo> {
        const response = await this.getActiveVersionUsingGETRaw();
        return await response.value();
    }

    /**
     * getAllProduktKonfigurationen
     */
    async getAllProduktKonfigurationenUsingGETRaw(): Promise<runtime.ApiResponse<Array<ProduktKonfiguration>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/admin/produktkonfigurationen`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProduktKonfigurationFromJSON));
    }

    /**
     * getAllProduktKonfigurationen
     */
    async getAllProduktKonfigurationenUsingGET(): Promise<Array<ProduktKonfiguration>> {
        const response = await this.getAllProduktKonfigurationenUsingGETRaw();
        return await response.value();
    }

    /**
     * getAvailableVersions
     */
    async getAvailableVersionsUsingGETRaw(): Promise<runtime.ApiResponse<Array<ProduktKonfigurationVersionInfo>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest/admin/versions/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProduktKonfigurationVersionInfoFromJSON));
    }

    /**
     * getAvailableVersions
     */
    async getAvailableVersionsUsingGET(): Promise<Array<ProduktKonfigurationVersionInfo>> {
        const response = await this.getAvailableVersionsUsingGETRaw();
        return await response.value();
    }

}
