import React, {FunctionComponent, useState} from 'react';
import {useConfigContext, useInputContext} from 'Context';
import {MultiSelect, Rating} from 'ui/_Shared';

export const Themenschwergebiete: FunctionComponent = () => {
    const {input, setInput} = useInputContext();
    const {seite3} = useConfigContext();
    const optionsForBranche = seite3.frage1.options[input.branche as 'anwalt' | 'notar' | 'stbwp' | 'verwaltung' | 'unternehmen' | 'verband'];
    const [showAll, setShowAll] = useState<boolean>(input.themengebiete?.reduce<boolean>(
        (prev, cur) => (prev || !optionsForBranche.map(option => option.label).includes(cur)), false) || false);

    return <>
        <div className='textblock'>
            <h2>{seite3.title}</h2>
        </div>
        <MultiSelect label={seite3.frage1.label}
                     helperText={seite3.frage1.helperText}
                     value={input.themengebiete || []}
                     onChangeHandler={val => setInput(input => ({...input, themengebiete: val as Array<string>}))}
                     options={showAll ? seite3.frage1.options.alle : optionsForBranche}/>
        {!showAll && <div className='advisory-tool__link-wrapper'>
            <span className='text-icon-link text-icon-link--link' onClick={() => setShowAll(oldValue => !oldValue)}>
                <span className='text-icon-link__icon'/>
                <span className='text-icon-link__text' style={{cursor: 'pointer'}}>{seite3.frage1.alleLabel}</span>
            </span>
        </div>}
        <Rating label={seite3.frage2.label}
                value={input.nutzung || ''}
                labelMax={seite3.frage2.maxLabel}
                labelMin={seite3.frage2.minLabel}
                onChangeHandler={val => setInput(input => ({...input, nutzung: val as number}))}
                options={seite3.frage2.options}/>
    </>;
};