import React, {FunctionComponent, useEffect, useState} from 'react';

interface KontaktFormTextareaProps {
    id: string;
    label: string;
    value: string;
    onChangeHandler: (value: string) => void;
    errorState?: boolean;
    maxLength?: number;
}

export const KontaktFormTextarea: FunctionComponent<KontaktFormTextareaProps> = ({
                                                                                     id,
                                                                                     label,
                                                                                     value,
                                                                                     onChangeHandler,
                                                                                     errorState,
                                                                                     maxLength = 420
                                                                                 }) => {
    const [touched, setTouched] = useState<boolean>(false);

    useEffect(() => {
        setTouched(false);
    }, [errorState, value]);

    return (
        <fieldset className='form__group'>
            <div className='form__row row'>
                <div className='form__col col col-12'>
                    <div className={['form__field', (!touched && errorState) ? 'form__field--error' : ''].join(' ')}>
                        <div className='textarea'>
                            <div className='textarea__inner'>
                                <div className='textarea__label-container'>
                                    <label className='textarea__label'
                                           htmlFor='textarea-97'>
                                        <span
                                            className='textarea__label-text'>{label}</span>
                                    </label>
                                </div>
                                <div className='textarea__element-container'>
                                                                        <textarea id={id}
                                                                                  value={value}
                                                                                  onChange={e => onChangeHandler(e.target.value)}
                                                                                  className='textarea__element'
                                                                                  maxLength={maxLength}/>
                                </div>
                                <div className='textarea__maxlength-container'
                                     style={{display: 'block'}}>
                                    <small
                                        className='textarea__maxlength form__mandatory-info'>{maxLength - value.length} Zeichen</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    );
};