import React, {FunctionComponent, useState} from 'react';
import {ProduktGruppe as ProduktGruppeModel} from '@juris/api-gen';
import {ProduktKarte} from './ProduktKarte';

interface ProduktGruppeProps {
    produktGruppe: ProduktGruppeModel;
    index: number;
}

export const ProduktGruppe: FunctionComponent<ProduktGruppeProps> = ({produktGruppe, index}) => {
    const [openState, setOpenState] = useState<boolean>(false);
    const [openStateMobile, setOpenStateMobile] = useState<boolean>(false);

    return (
        <div className={['col-12', 'col-lg-5', index % 2 > 0 ? 'offset-lg-1' : ''].join(' ')}>
            <div className='expertise-container__col'>
                <div className={[
                    'expertise-container__item',
                    'expertise-container__item--visible',
                    openState ? 'expertise-container__item--show-more' : '',
                    openStateMobile ? 'expertise-container__item--open' : ''
                ].join(' ')}>
                    <h3 className='expertise-container__item-title'
                        onClick={() => setOpenStateMobile(state => !state)}>
                        <span className='expertise-container__icon-close'/>
                        <span className='expertise-container__icon-open'/>
                        <span className='expertise-container__item-title-text'>{produktGruppe.titel}</span>
                    </h3>
                    {!!produktGruppe.produkte?.length && produktGruppe.produkte?.length > 3 &&
                        <span className='expertise-container__item-show-more'
                              onClick={() => setOpenState(state => !state)}>
                        <span className='expertise-container__item-show-more-icon'/>
                        <span className='expertise-container__item-show-more-text'>{
                            openState ? 'weniger anzeigen' : 'alle anzeigen'
                        }</span>
                    </span>}
                    <div className='expertise-container__item-body'>
                        <div className='expertise-container__item-row row'>
                            {produktGruppe.produkte?.map((produkt, index) =>
                                <ProduktKarte key={index} isFirst={index === 0}{...produkt}/>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
