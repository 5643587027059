import React, {FunctionComponent} from 'react';
import {useConfigContext, useInputContext} from 'Context';
import {Radio} from 'ui/_Shared';

export const Start: FunctionComponent = () => {
    const {input, resetContext} = useInputContext();
    const {seite1} = useConfigContext();

    return <>
        <div className='textblock'>
            <h2>{seite1.title}</h2>
            <div dangerouslySetInnerHTML={{__html: seite1.text as string}}/>
        </div>
        <Radio label={seite1.frage1.label}
               value={input.branche || ''}
               onChangeHandler={value => resetContext(value as string)}
               buttonSize='large'
               options={seite1.frage1.options}/>
    </>;
};